import '@babel/polyfill';

import './utils';

import React from 'react';
import { render } from 'react-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { SnackbarProvider } from 'notistack';

import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import BasePage from './containers/base-page';

import HomePage from 'bundle-loader?lazy&name=homepage!./containers/home';

import DashboardPage from 'bundle-loader?lazy&name=dashboardpage!./containers/dashboard';

import HRSpotPage from 'bundle-loader?lazy&name=hrspotpage!./containers/hr-spot';
import LeadGenPage from 'bundle-loader?lazy&name=leadgenpage!./containers/lead-gen';
import ITRoadmaps from 'bundle-loader?lazy&name=itroadmaps!./containers/it-roadmaps';
import Retail from 'bundle-loader?lazy&name=retail!./containers/retail';
import DistributionAccount from 'bundle-loader?lazy&name=distribution-account!./containers/distribution-account';

import NewQuotePage from 'bundle-loader?lazy&name=newquotepage!./containers/quoting/NewQuotePage';
import SearchQuotePage from 'bundle-loader?lazy&name=searchquotepage!./containers/quoting/SearchQuotePage';
import QuoteEscalationReport from 'bundle-loader?lazy&name=quoteescalationreport!./containers/quote-escalation-report';

// import AccountApplicationPage from 'bundle-loader?lazy&name=accountapplicationpage!./containers/account-application/AccountApplicationPage';
// import CompletedAccountApplicationPage from 'bundle-loader?lazy&name=completedaccountapplicationpage!./containers/account-application/CompletedAccountApplicationPage';
// import FullAccountApplicationPage from 'bundle-loader?lazy&name=fullaccountapplicationpage!./containers/account-application/FullAccountApplicationPage';
// import SubmittedAccountApplicationPage from 'bundle-loader?lazy&name=submittedaccountapplicationpage!./containers/account-application/SubmittedAccountApplicationPage';

import TimeSheetPage from 'bundle-loader?lazy&name=timesheetpage!./containers/time-sheet';
import ProjectsPage from 'bundle-loader?lazy&name=projectspage!./containers/projects';
import InvoicePage from 'bundle-loader?lazy&name=invoicepage!./containers/invoice';
import ImpersonatePage from 'bundle-loader?lazy&name=impersonatepage!./containers/impersonate';
import DirectoryPage from 'bundle-loader?lazy&name=directorypage!./containers/directory';
import OrgChartPage from 'bundle-loader?lazy&name=orgchartpage!./containers/org-chart';
import PriceCheckPage from 'bundle-loader?lazy&name=pricecheckpage!./containers/price-check';
import ProfilePage from 'bundle-loader?lazy&name=profilepage!./containers/profile';

import PhoneBase from 'bundle-loader?lazy&name=phonebase!./containers/reports/phone-report/PhoneBase';

import ExpenseMileagePage from 'bundle-loader?lazy&name=expensemileagepage!./containers/expense/routes/expense-mileage-route';
import ExpenseReimbursementPage from 'bundle-loader?lazy&name=expensereimbursementpage!./containers/expense/routes/expense-reimbursement-route';
import ExpenseSearchPage from 'bundle-loader?lazy&name=expensesearchpage!./containers/expense/routes/expense-search-route';
import ExpenseNoticePage from 'bundle-loader?lazy&name=expensenoticepage!./containers/expense/routes/expense-notice-route';
import ExpenseExportPage from 'bundle-loader?lazy&name=expenseexportpage!./containers/expense/routes/expense-export-route';
import ExpenseOdometerReport from 'bundle-loader?lazy&name=expenseodometerreport!./containers/expense/routes/expense-odometer-report-route';
import GLAccountPage from 'bundle-loader?lazy&name=glaccountpage!./containers/expense/routes/glaccount-route';
import PurchaseRequestPage from 'bundle-loader?lazy&name=purchaserequestpage!./containers/purchase-request/purchase-request';

import EscalationDiscountsPage from 'bundle-loader?lazy&name=escalationdiscountspage!./containers/quote-tools/escalation-discounts';

import SalesProgramsPage from 'bundle-loader?lazy&name=salesprogramspage!./containers/sales-programs';

import TruckLogPrintPage from 'bundle-loader?lazy&name=trucklogprintpage!./containers/truck-log/print';
import TruckLogViewEditPage from 'bundle-loader?lazy&name=trucklogvieweditpage!./containers/truck-log/view';

import ILQAFormPage from 'bundle-loader?lazy&name=ilqaformpage!./containers/il-qa-form/ILQAFormPage';

import CICStoreCapacityPage from 'bundle-loader?lazy&name=cicstorecapacitypage!./containers/cic-store-capacity';
import CICStorePage from 'bundle-loader?lazy&name=cicstorepage!./containers/cic-store';

import CustomerCatalogRestrictionPage from 'bundle-loader?lazy&name=customercatalogrestrictionpage!./containers/catalog-restrictions/customer';
import EStoreContactPage from 'bundle-loader?lazy&name=estorecontactpage!./containers/estore/contact';
import CustomerCatalogFavouritesPage from 'bundle-loader?lazy&name=customercatalogfavouritespage!./containers/catalog-favourites/customer';

import LoginPage from 'bundle-loader?lazy&name=loginpage!./containers/authentication/LoginPage';
import LogoutPage from 'bundle-loader?lazy&name=logoutpage!./containers/authentication/LogoutPage';

import HomeIcon from '@material-ui/icons/Home';
import ToolsIcon from '@material-ui/icons/Build';
import ClockIcon from '@material-ui/icons/AccessTime';
import CreateIcon from '@material-ui/icons/Create';
import ForumIcon from '@material-ui/icons/Forum';
import ContactsIcon from '@material-ui/icons/Contacts';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TruckIcon from '@material-ui/icons/LocalShipping';
import PhoneIcon from '@material-ui/icons/LocalPhone';
import PriceCheckIcon from '@material-ui/icons/MonetizationOn';
import EventIcon from '@material-ui/icons/Event';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import TransformIcon from '@material-ui/icons/Transform';

import ExploreIcon from '@material-ui/icons/Explore';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import RoadImg from './assets/images/road.png';
import PointOfSaleImage from './assets/images/point-of-sale.png';

import AssessmentIcon from '@material-ui/icons/Assessment';
import createStore from './datastore/createStore';
const { store, persistor } = createStore();

import config from './config';

import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

firebase.initializeApp(config.firebase);

import './assets/css/global-styles.css';
import './theme/assets/scss/material-dashboard-pro-react.scss';

import 'rc-slider/assets/index.css';

const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        height: ''
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '0px'
      }
    },
    MuiListItemText: {
      root: {
        paddingLeft: '16px'
      }
    },
    MuiToolbar: {
      root: {
        display: 'flex'
      }
    }
  },
  typography: {
    useNextVariants: true
  },
  palette: {
    background: {
      default: '#F3F3F3'
    }
  }
});

const RoadIcon = () => <img src={RoadImg} alt="IT Roadmap" style={{ width: 24 }} />;
const PointOfSaleIcon = () => <img src={PointOfSaleImage} alt="Point of Sale" style={{ width: 24 }} />;

const renderPage = () =>
  render(
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Provider store={store}>
              <ReactReduxFirebaseProvider
                firebase={firebase}
                dispatch={store.dispatch}
                config={{ enableLogging: false }}
              >
                <PersistGate persistor={persistor}>
                  <BrowserRouter>
                    <Route
                      path="/redirect/quoting/new"
                      component={() => {
                        window.location.href = 'https://quotes.swish.ca/';
                        return null;
                      }}
                    />
                    <Route
                      path="/redirect/quoting/search"
                      component={() => {
                        window.location.href = 'https://quotes.swish.ca/search';
                        return null;
                      }}
                    />
                    <Route
                      path={'/'}
                      render={props => {
                        return (
                          <BasePage
                            routes={[
                              {
                                public: true,
                                collapse: false,
                                name: 'Home',
                                icon: HomeIcon,
                                bundle: HomePage,
                                path: '/home'
                              },
                              {
                                public: true,
                                collapse: false,
                                name: 'Directory',
                                path: '/directory',
                                authenticated: true,
                                icon: ContactsIcon,
                                bundle: DirectoryPage
                              },
                              {
                                collapse: true,
                                name: 'Quoting',
                                icon: MoneyIcon,
                                bundle: HomePage,
                                path: '/quoting/',
                                state: 'quoting',
                                hideFromCharlotte: true,
                                views: [
                                  {
                                    public: true,

                                    path: '/quoting/new',

                                    url:
                                      process.env.NODE_ENV == 'production'
                                        ? 'https://quotes.swish.ca/'
                                        : 'https://staging.quotes.swish.ca/',

                                    authenticated: true,
                                    name: 'New Quote',
                                    fullWidth: true,
                                    bundle: NewQuotePage
                                  },
                                  {
                                    public: true,

                                    path: '/quoting/search',

                                    url:
                                      process.env.NODE_ENV == 'production'
                                        ? 'https://quotes.swish.ca/search'
                                        : 'https://staging.quotes.swish.ca/search',
                                    authenticated: true,
                                    name: 'Search Quotes',
                                    fullWidth: true,
                                    bundle: SearchQuotePage
                                  },
                                  {
                                    path: '/quoting/escalation-report',
                                    authenticated: true,
                                    name: 'Escalation Report',
                                    fullWidth: true,
                                    bundle: QuoteEscalationReport
                                  }
                                ]
                              },

                              // {
                              //   'public': true,
                              //   'collapse': true,
                              //   'name': 'Applications',
                              //   'icon': AccountBalanceIcon,
                              //   'bundle': AccountApplicationPage,
                              //   'path': '/applications',
                              //   'hideFromCharlotte': true,

                              //   'state': 'applications',
                              //   'views': [
                              //     {
                              //       'public': true,
                              //       'path': '/applications/new',
                              //       'authenticated': true,
                              //       'name': 'New / Edit',
                              //       'fullWidth': true,
                              //       'bundle': AccountApplicationPage,
                              //     },
                              //     {
                              //       'path': '/applications/submitted',
                              //       'name': 'View Submitted',
                              //       'fullWidth': true,
                              //       'bundle': SubmittedAccountApplicationPage,
                              //     },
                              //     {
                              //       'path': '/applications/completed',
                              //       'authenticated': true,
                              //       'name': 'Search Completed',
                              //       'fullWidth': true,
                              //       'bundle': CompletedAccountApplicationPage,
                              //     },
                              //   ],
                              // },
                              {
                                collapse: true,
                                name: 'Expenses',
                                icon: ReceiptIcon,
                                bundle: HomePage,
                                path: '/expense',
                                state: 'expense',
                                hideFromCharlotte: true,

                                public: true,
                                views: [
                                  {
                                    public: true,
                                    path: '/expense/mileage',
                                    authenticated: true,
                                    name: 'New/Edit Mileage',
                                    fullWidth: true,
                                    bundle: ExpenseMileagePage
                                  },
                                  {
                                    public: true,
                                    path: '/expense/reimbursement',
                                    authenticated: true,
                                    name: 'New/Edit Reimbursement',
                                    fullWidth: true,
                                    bundle: ExpenseReimbursementPage
                                  },
                                  {
                                    public: true,
                                    path: '/expense/search',
                                    authenticated: true,
                                    name: 'Approval / Search',
                                    fullWidth: true,
                                    bundle: ExpenseSearchPage
                                  },
                                  {
                                    authenticated: true,
                                    hidden: true,
                                    path: '/expense/notice/:listId',
                                    bundle: ExpenseNoticePage,
                                    name: 'Notices'
                                  },
                                  {
                                    authenticated: true,
                                    path: '/expense/notice',
                                    bundle: ExpenseNoticePage,
                                    name: 'Notices'
                                  },
                                  {
                                    path: '/expense/export',
                                    authenticated: true,
                                    name: 'Export',
                                    fullWidth: true,
                                    bundle: ExpenseExportPage
                                  },
                                  {
                                    path: '/expense/odometer-report',
                                    authenticated: true,
                                    name: 'Odometer Report',
                                    fullWidth: true,
                                    bundle: ExpenseOdometerReport
                                  },
                                  {
                                    path: '/expense/purchase-request',
                                    authenticated: true,
                                    name: 'Purchase Request',
                                    fullWidth: true,
                                    bundle: PurchaseRequestPage
                                  },
                                  {
                                    path: '/expense/glaccount',
                                    authenticated: true,
                                    name: 'GL Accounts',
                                    fullWidth: true,
                                    bundle: GLAccountPage
                                  }
                                ]
                              },
                              {
                                hidden: true,
                                collapse: false,
                                name: 'Price Check',
                                authenticated: true,
                                icon: PriceCheckIcon,
                                bundle: PriceCheckPage,
                                path: '/price-check'
                              },
                              {
                                collapse: true,
                                name: 'Account Creation',
                                icon: PointOfSaleIcon,
                                bundle: HomePage,
                                path: '/accounts/',
                                state: 'accounts',
                                hideFromCharlotte: true,
                                public: true,

                                views: [
                                  {
                                    hidden: true,
                                    public: true,
                                    path: '/accounts/distribution/:queueId',
                                    authenticated: true,
                                    name: 'Distribution Account',
                                    fullWidth: true,
                                    bundle: DistributionAccount
                                  },
                                  {
                                    public: true,
                                    path: '/accounts/distribution',
                                    authenticated: true,
                                    name: 'Distribution Account',
                                    fullWidth: true,
                                    bundle: DistributionAccount
                                  },
                                  {
                                    hidden: true,
                                    public: true,
                                    path: '/accounts/retail/:queueId',
                                    authenticated: true,
                                    name: 'Retail',
                                    fullWidth: true,
                                    bundle: Retail
                                  },
                                  {
                                    public: true,
                                    path: '/accounts/retail',
                                    authenticated: true,
                                    name: 'Retail',
                                    fullWidth: true,
                                    bundle: Retail
                                  }
                                ]
                              },

                              {
                                public: true,
                                collapse: false,
                                name: 'HR Spot',
                                authenticated: true,
                                icon: SupervisedUserCircleIcon,
                                bundle: HRSpotPage,
                                path: '/hr-spot'
                              },

                              {
                                public: true,
                                collapse: false,
                                name: 'Let Us Help Program',
                                authenticated: true,
                                icon: AssessmentIcon,
                                bundle: LeadGenPage,
                                path: '/lead-gen'
                              },
                              {
                                public: true,
                                collapse: false,
                                name: 'Sales Center',
                                icon: AssignmentIcon,
                                bundle: SalesProgramsPage,
                                path: '/sales-center',
                                hideFromCharlotte: true
                              },

                              {
                                public: true,
                                collapse: true,
                                name: 'Truck Log',
                                icon: TruckIcon,
                                bundle: HomePage,
                                path: '/truck-log/',
                                state: 'truck-log',
                                hideFromCharlotte: true,

                                views: [
                                  {
                                    path: '/truck-log/print-log',
                                    authenticated: true,
                                    name: 'Print Log',
                                    bundle: TruckLogPrintPage
                                  },
                                  {
                                    public: true,
                                    path: '/truck-log/view-edit-log',
                                    authenticated: true,
                                    name: 'View / Edit Log',
                                    bundle: TruckLogViewEditPage
                                  }
                                ]
                              },

                              {
                                public: true,
                                collapse: false,
                                name: 'Swish.ca Management',
                                path: 'https://configurator.swish.ca',
                                url:
                                  process.env.NODE_ENV == 'production'
                                    ? 'https://configurator.swish.ca'
                                    : 'https://staging.configurator.swish.ca',
                                authenticated: true,
                                icon: ToolsIcon,
                                hideFromCharlotte: true
                                // bundle: DirectoryPage
                              },

                              // {
                              //   collapse: true,
                              //   public: true,
                              //   name: 'Reports',
                              //   path: '/reports',
                              //   icon: AssessmentIcon,
                              //   bundle: PhoneBase,
                              //   hideFromCharlotte: true,
                              //   views: [
                              //     {
                              //       fullPage: true,
                              //       public: false,
                              //       hidden: true,
                              //       authenticated: true,
                              //       path: '/reports/dashboard',
                              //       name: 'Dashboard',
                              //       icon: ExploreIcon,
                              //       bundle: DashboardPage
                              //     },
                              //     {
                              //       path: '/reports/phone-report',
                              //       name: 'Phone Report',
                              //       icon: PhoneIcon,
                              //       bundle: PhoneBase
                              //     }
                              //   ]
                              // },

                              {
                                collapse: true,
                                name: 'Tools',
                                icon: ToolsIcon,
                                bundle: HomePage,
                                path: '/tools/',
                                state: 'tools',
                                hideFromCharlotte: true,

                                views: [
                                  {
                                    public: false,
                                    path: '/tools/org-chart',
                                    authenticated: true,
                                    name: 'Org Chart',
                                    icon: TransformIcon,
                                    bundle: OrgChartPage,
                                    hideFromCharlotte: true
                                  },
                                  // {
                                  //   public: true,
                                  //   path: '/tools/directory',
                                  //   authenticated: true,
                                  //   name: 'Directory',
                                  //   icon: ContactsIcon,
                                  //   bundle: DirectoryPage
                                  // },
                                  {
                                    public: false,
                                    path: '/tools/profile',
                                    authenticated: true,
                                    name: 'Profile',
                                    icon: CreateIcon,
                                    bundle: ProfilePage,
                                    hideFromCharlotte: true
                                  },
                                  {
                                    path: '/tools/time-sheet',
                                    authenticated: true,
                                    name: 'Time Sheet',
                                    icon: ClockIcon,
                                    bundle: TimeSheetPage,
                                    hideFromCharlotte: true
                                  },
                                  {
                                    path: '/tools/projects',
                                    authenticated: true,
                                    name: 'Projects',
                                    icon: ForumIcon,
                                    bundle: ProjectsPage,
                                    hideFromCharlotte: true
                                  },
                                  {
                                    path: '/tools/invoice',
                                    authenticated: true,
                                    name: 'Invoice',
                                    icon: TrackChangesIcon,
                                    bundle: InvoicePage,
                                    hideFromCharlotte: true
                                  },
                                  {
                                    path: '/tools/impersonate',
                                    authenticated: true,
                                    name: 'Impersonate',
                                    icon: PersonOutlineIcon,
                                    bundle: ImpersonatePage,
                                    hideFromCharlotte: true
                                  }
                                ]
                              },
                              // {
                              //   collapse: true,
                              //   public: true,
                              //   name: 'CIC Store',
                              //   icon: ToolsIcon,
                              //   bundle: HomePage,
                              //   path: '/cic-store/',
                              //   state: 'cic-store',
                              //   hideFromCharlotte: true,

                              //   views: [
                              //     {
                              //       public: true,
                              //       path: '/cic-store/capacity',
                              //       authenticated: true,
                              //       name: 'Capacity Control',
                              //       icon: ContactsIcon,
                              //       bundle: CICStoreCapacityPage
                              //     }
                              //   ]
                              // },
                              {
                                collapse: true,
                                name: 'Quote Tools',
                                icon: ToolsIcon,
                                bundle: HomePage,
                                path: '/quote-tools/',
                                state: 'quote-tools',
                                hideFromCharlotte: true,

                                views: [
                                  {
                                    public: true,
                                    path: '/quote-tools/escalation-discounts',
                                    authenticated: true,
                                    name: 'Escalation Discounts',
                                    icon: ContactsIcon,
                                    bundle: EscalationDiscountsPage
                                  }
                                ]
                              },

                              {
                                public: true,
                                collapse: false,
                                name: 'IT Roadmaps',
                                authenticated: true,
                                icon: RoadIcon,
                                bundle: ITRoadmaps,
                                path: '/it-roadmaps'
                              },
                              {
                                name: 'I&L Q/A Form',
                                icon: EventIcon,
                                bundle: ILQAFormPage,
                                path: '/il-qa-form',
                                state: 'il-qa-form',
                                hideFromCharlotte: true
                              },
                              {
                                public: true,
                                fullPage: true,
                                footer: true,
                                authenticated: false,
                                path: '/auth/login',
                                name: 'LOGIN',
                                bundle: LoginPage
                              },
                              {
                                public: true,
                                fullPage: true,
                                footer: true,
                                authenticated: true,
                                path: '/auth/logout',
                                name: 'LOGOUT',
                                bundle: LogoutPage
                              },
                              {
                                public: true,
                                footer: true,
                                hideFromCharlotte: true,
                                path: 'https://hq.swishclean.com',
                                url: 'https://hq.swishclean.com',
                                name: 'RETURN TO HQ',
                                bundle: HomePage
                              },

                              // {
                              //   'public': true,
                              //   'path': '/application/full',
                              //   'fullPage': true,
                              //   'hidden': true,
                              //   'bundle': FullAccountApplicationPage,

                              // },
                              {
                                public: true,
                                path: '/cic-store',
                                fullPage: true,
                                hidden: true,
                                bundle: CICStorePage
                              },
                              {
                                public: true,
                                path: '/catalog-restrictions/customer',
                                fullPage: true,
                                hidden: true,
                                bundle: CustomerCatalogRestrictionPage
                              },

                              {
                                public: true,
                                path: '/catalog-favourites/customer',
                                fullPage: true,
                                hidden: true,
                                bundle: CustomerCatalogFavouritesPage
                              },

                              {
                                public: true,
                                path: '/estore/contact',
                                fullPage: true,
                                hidden: true,
                                bundle: EStoreContactPage
                              },
                              {
                                public: true,
                                pathFrom: '/#/pages/quoting/new',
                                path: '/quoting/new',
                                redirect: true,
                                hideFromCharlotte: true
                              },
                              {
                                public: true,
                                pathFrom: '/#/pages/quoting/search',
                                path: '/quoting/search',
                                redirect: true,
                                hideFromCharlotte: true
                              }

                              // {
                              //   'public': true,
                              //   'pathFrom': '/account-application',
                              //   'path': '/application/full',
                              //   'redirect': true,
                              //   'hideFromCharlotte': true,

                              // },
                            ]}
                            {...props}
                          />
                        );
                      }}
                    />
                  </BrowserRouter>
                </PersistGate>
              </ReactReduxFirebaseProvider>
            </Provider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </React.Fragment>,
    document.getElementById('react-app')
  );

renderPage();

if (module.hot) {
  module.hot.accept(
    [
      './utils',

      './containers/base-page',

      'bundle-loader?lazy&name=homepage!./containers/home',

      'bundle-loader?lazy&name=dashboardpage!./containers/dashboard',
      'bundle-loader?lazy&name=leadgenpage!./containers/lead-gen',
      'bundle-loader?lazy&name=hrspotpage!./containers/hr-spot',
      'bundle-loader?lazy&name=itroadmaps!./containers/it-roadmaps',
      'bundle-loader?lazy&name=retail!./containers/retail',
      'bundle-loader?lazy&name=distribution-account!./containers/distribution-account',

      'bundle-loader?lazy&name=newquotepage!./containers/quoting/NewQuotePage',
      'bundle-loader?lazy&name=searchquotepage!./containers/quoting/SearchQuotePage',
      'bundle-loader?lazy&name=quoteescalationreport!./containers/quote-escalation-report',

      // 'bundle-loader?lazy&name=accountapplicationpage!./containers/account-application/AccountApplicationPage',
      // 'bundle-loader?lazy&name=completedaccountapplicationpage!./containers/account-application/CompletedAccountApplicationPage',
      // 'bundle-loader?lazy&name=fullaccountapplicationpage!./containers/account-application/FullAccountApplicationPage',
      // 'bundle-loader?lazy&name=submittedaccountapplicationpage!./containers/account-application/SubmittedAccountApplicationPage',

      'bundle-loader?lazy&name=timesheetpage!./containers/time-sheet',
      'bundle-loader?lazy&name=projectspage!./containers/projects',
      'bundle-loader?lazy&name=invoicepage!./containers/invoice',
      'bundle-loader?lazy&name=impersonatepage!./containers/impersonate',
      'bundle-loader?lazy&name=directorypage!./containers/directory',
      'bundle-loader?lazy&name=profilepage!./containers/profile',
      'bundle-loader?lazy&name=orgchartpage!./containers/org-chart',
      'bundle-loader?lazy&name=pricecheckpage!./containers/price-check',

      'bundle-loader?lazy&name=expensemileagepage!./containers/expense/routes/expense-mileage-route',
      'bundle-loader?lazy&name=expensereimbursementpage!./containers/expense/routes/expense-reimbursement-route',
      'bundle-loader?lazy&name=expensesearchpage!./containers/expense/routes/expense-search-route',
      'bundle-loader?lazy&name=expensenoticepage!./containers/expense/routes/expense-notice-route',
      'bundle-loader?lazy&name=expenseexportpage!./containers/expense/routes/expense-export-route',
      'bundle-loader?lazy&name=expenseodometerreport!./containers/expense/routes/expense-odometer-report-route',
      'bundle-loader?lazy&name=glaccountpage!./containers/expense/routes/glaccount-route',

      'bundle-loader?lazy&name=purchaserequestpage!./containers/purchase-request/purchase-request',

      'bundle-loader?lazy&name=phonebase!./containers/reports/phone-report/PhoneBase',
      'bundle-loader?lazy&name=phonebase!./containers/reports/phone-report/DetailPage',

      'bundle-loader?lazy&name=escalationdiscountspage!./containers/quote-tools/escalation-discounts',

      'bundle-loader?lazy&name=salesprogramspage!./containers/sales-programs',

      'bundle-loader?lazy&name=trucklogprintpage!./containers/truck-log/print',
      'bundle-loader?lazy&name=trucklogvieweditpage!./containers/truck-log/view',

      'bundle-loader?lazy&name=ilqaformpage!./containers/il-qa-form/ILQAFormPage',

      'bundle-loader?lazy&name=cicstorecapacitypage!./containers/cic-store-capacity',
      'bundle-loader?lazy&name=cicstorepage!./containers/cic-store',

      'bundle-loader?lazy&name=customercatalogrestrictionpage!./containers/catalog-restrictions/customer',
      'bundle-loader?lazy&name=customercatalogfavouritespage!./containers/catalog-favourites/customer',
      'bundle-loader?lazy&name=estorecontactpage!./containers/estore/contact',

      'bundle-loader?lazy&name=loginpage!./containers/authentication/LoginPage',
      'bundle-loader?lazy&name=logoutpage!./containers/authentication/LogoutPage'
    ],
    () => renderPage()
  );
}
